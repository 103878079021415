export interface ISeller {
  id: number,
  name: string,
  token: string
}

export enum SellerActionsTypes {
  FETCH_SELLER = "FETCH_SELLER",
  UPDATE_SELLER = "UPDATE_SELLER",
  CLEAR_SELLER = "CLEAR_SELLER"
}