import {HttpService} from "../../App/services/http";
import {IFilter} from "../../App/interfaces/filter";
import {ISeller} from "../interfaces/seller";
import {IGatewayInput} from "../interfaces/inputs/gateway.inputs.interface";

const items = (token: string, filter: IFilter) => {
  return HttpService.get(`/seller/${token}/gateways`, filter)
    .then(response => {
      return response
    })
}

const create = (data: IGatewayInput) => {
  return HttpService.post(`/seller/${data.seller}/gateway`, {
    ...data,
    method: data.method.key,
    merchants: data.merchants.map(merchant => ({
      id: merchant.id,
      settings: merchant.settings
    }))
  })
    .then(response => {
      const {data} = response
      return data
    })
}

const update = (id: number, data: IGatewayInput) => {
  return HttpService.put(`/seller/${data.seller}/gateway/${id}`, {
    ...data,
    method: data.method.key,
    merchants: data.merchants.map(merchant => ({
      id: merchant.id,
      settings: merchant.settings
    }))
  })
    .then(response => {
      return response
    })
}

const remove = (id: number, seller: ISeller) => {
  return HttpService.delete(`/seller/${seller.token}/gateway/${id}`)
    .then(response => {
      return response
    })
}

export const GatewayServices = {
  items,
  create,
  update,
  delete: remove
}