import React, {ReactElement} from 'react';

import {TextField} from "./TextField";
import {IconButton, InputAdornment, TextFieldProps} from "@mui/material";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export function HiddenField(props: TextFieldProps): ReactElement | null {
  const [show, setShow] = React.useState(false)

  const handleClick = () => setShow((show) => !show)

  const handleMouse = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
  }

  return <TextField
    {...props}
    type={show ? 'text' : 'password'}
    InputProps={{
      endAdornment: <InputAdornment position="end">
        <IconButton
          size="small"
          onClick={handleClick}
          onMouseDown={handleMouse}
        >
          {show ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      </InputAdornment>
    }}
  />
}