import {HttpService} from "../../App/services/http";
import {ISettingsInput} from "../interfaces/inputs/settings.inputs.interface";

const seller = (token: string) => {
  return HttpService.get(`/seller/${token}`)
    .then(response => {
      const {data} = response
      return data
    })
}

const update = (token: string, data: ISettingsInput) => {
  return HttpService.put(`/seller/${token}`, data)
    .then(response => {
      return response
    })
}

export const SellerServices = {
  seller,
  update
}