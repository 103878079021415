import {ReactElement} from "react";

interface Props {
  height?: number
}

export function Label(props: Props): ReactElement | null {
  const { height } = props

  return (
    <img height={height} src='/label.svg' alt="forkout"/>
  );
}
