import React, {ReactElement, useState} from "react"
import {
  Grid,
  styled, Button as MUIButton, Typography, IconButton
} from "@mui/material";
import {ContentCopy as ContentCopyIcon} from "@mui/icons-material";
import {IInvoice} from "../../interfaces/invoice";
import {Popover} from "../../../App/components/Popover";

export const Button = styled(MUIButton)(({theme}) => ({
  fontSize: '14px',
  fontWeight: 400,
  color: 'rgba(0, 0, 0, 0.87)',
  textTransform: 'unset',
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)'
  }
}));

export const Item = styled(Grid)(({theme}) => ({
  //padding: '8px',
}));

interface Props {
  invoice: IInvoice
}

export function Transaction(props: Props): ReactElement | null {
  const {invoice} = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <Button
        onClick={handleClick}
      >
        {invoice.number}
      </Button>
      <Popover
        sx={{
          "& .MuiPaper-root": {
            padding: '16px'
          }
        }}
        disableScrollLock
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
          {[
            {
              name: 'Номер заказа',
              value: invoice.number
            },
            {
              name: 'Номер транзакции',
              value: invoice.token
            }
          ].map((item, index) => (
            <Grid item key={index}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Item item>
                  <Typography
                    noWrap
                    sx={{
                      fontWeight: 500,
                      lineHeight: 1.57143,
                      fontSize: '0.875rem'
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    noWrap
                    sx={{
                      lineHeight: 1.57143,
                      fontSize: '0.875rem',
                      fontWeight: 400,
                      color: 'rgb(99, 115, 129)'
                    }}
                  >
                    {item.value}
                  </Typography>
                </Item>
                <Item item>
                  <IconButton
                    size="small"
                    onClick={async () => {
                      await navigator.clipboard.writeText(item.value)
                      handleClose()
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </Item>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Grid>
  )
}
