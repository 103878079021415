import React, {ReactElement} from "react";

import {
  styled, Grid, Divider, GridProps, Typography, useTheme, useMediaQuery, Box as MUIBox
} from '@mui/material';

export const Item = styled((props: GridProps) => (
  <Grid
    item
    {...props}
  />
))(({theme}) => ({
  width: '100%'
}));

export const Box = styled(MUIBox)(({theme}) => ({
  width: '100%',
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: 'white'
}));

export function Signature(): ReactElement | null {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <React.Fragment>
      <Item>
        <Divider
          sx={{
            borderColor: 'rgba(145, 158, 171, 0.2)',
            borderStyle: 'dashed',
            borderWidth: '0px 0px thin'
          }}
        />
      </Item>
      <Item>
        <Grid container direction={mobile ? "column" : "row" as 'column' | "row"} alignItems="flex-start" justifyContent="space-between" spacing={2}>
          <Grid item xs={2}>
            <Typography
              noWrap
              sx={{
                fontWeight: 500,
                lineHeight: 1.57143,
                fontSize: '0.875rem'
              }}
            >
              Параметры
            </Typography>
          </Grid>
          <Item xs={mobile ? 12 : 10}>
            <Box>
              <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1.57143,
                          fontSize: '0.75rem',
                          color: 'rgb(99, 115, 129)'
                        }}
                      >
                        Алгоритм
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.875rem',
                          lineHeight: 1.43
                        }}
                      >
                        HMAC
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1.57143,
                          fontSize: '0.75rem',
                          color: 'rgb(99, 115, 129)'
                        }}
                      >
                        Хеш-функция
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.875rem',
                          lineHeight: 1.43
                        }}
                      >
                        SHA256
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1.57143,
                          fontSize: '0.75rem',
                          color: 'rgb(99, 115, 129)'
                        }}
                      >
                        Секретный ключ
                      </Typography>
                      <Typography
                        noWrap
                        sx={{
                          lineHeight: 1.57143,
                          fontSize: '0.7rem',
                          fontWeight: 400,
                          color: 'rgb(0, 167, 111)'
                        }}
                      >
                        string
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.875rem',
                          lineHeight: 1.43
                        }}
                      >
                        Секретный ключ из настроек
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1.57143,
                          fontSize: '0.75rem',
                          color: 'rgb(99, 115, 129)'
                        }}
                      >
                        Строка данных
                      </Typography>
                      <Typography
                        noWrap
                        sx={{
                          lineHeight: 1.57143,
                          fontSize: '0.7rem',
                          fontWeight: 400,
                          color: 'rgb(0, 167, 111)'
                        }}
                      >
                        string
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.875rem',
                          lineHeight: 1.43
                        }}
                      >
                        Строка из данных запроса
                      </Typography>
                      <Typography
                        sx={{
                          lineHeight: 1.57143,
                          fontSize: '0.7rem',
                          fontWeight: 400,
                          color: 'rgb(99, 115, 129)'
                        }}
                      >
                        Для каждой пары (ключ, значение), в алфавитном порядке следования ключей, в строку записывается ключ, после символ ":" (двоеточие), далее значение. Каждая пара ключ:значение завершается символом ";" (точкой с запятой)
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Item>
        </Grid>
      </Item>
    </React.Fragment>
  );
}
