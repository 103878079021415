import {ReactElement} from "react";

interface Props {
  height?: number
  width?: number
}

export function Logo(props: Props): ReactElement | null {
  const { height, width } = props

  return (
    <img height={height} width={width} src='/logo.svg' alt="forkout"/>
  );
}
