import React, {ReactElement} from "react";

import {
  styled, Grid, Divider, GridProps, Typography, useTheme, useMediaQuery, Box as MUIBox
} from '@mui/material';

export const Item = styled((props: GridProps) => (
  <Grid
    item
    {...props}
  />
))(({theme}) => ({
  width: '100%'
}));

export const Box = styled(MUIBox)(({theme}) => ({
  width: '100%',
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: 'white'
}));

export function Notification(): ReactElement | null {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <React.Fragment>
      <Item>
        <Divider
          sx={{
            borderColor: 'rgba(145, 158, 171, 0.2)',
            borderStyle: 'dashed',
            borderWidth: '0px 0px thin'
          }}
        />
      </Item>
      <Item>
        <Grid container direction={mobile ? "column" : "row" as 'column' | "row"} alignItems="flex-start" justifyContent="space-between" spacing={2}>
          <Grid item xs={2}>
            <Typography
              noWrap
              sx={{
                fontWeight: 500,
                lineHeight: 1.57143,
                fontSize: '0.875rem'
              }}
            >
              Результат
            </Typography>
          </Grid>
          <Item xs={mobile ? 12 : 10}>
            <Box>
              <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
                <Grid item>
                  <Typography
                    noWrap
                    sx={{
                      fontWeight: 400,
                      lineHeight: 1.57143,
                      fontSize: '0.75rem',
                      whiteSpace: 'pre'
                    }}
                  >
                    {JSON.stringify({
                      'invoice_id': 'Номер заказа',
                      'amount': 'Сумма платежа',
                      'currency': 'Валюта',
                      'status': 'Статус',
                      'signature': 'Подпись запроса'
                    }, null, 4) }
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Item>
        </Grid>
      </Item>
      <Item>
        <Grid container direction={mobile ? "column" : "row" as 'column' | "row"} alignItems="flex-start" justifyContent="space-between" spacing={2}>
          <Grid item xs={2}>
            <Typography
              noWrap
              sx={{
                fontWeight: 500,
                lineHeight: 1.57143,
                fontSize: '0.875rem'
              }}
            >
              Статусы
            </Typography>
          </Grid>
          <Item xs={mobile ? 12 : 10}>
            <Box>
              <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1.57143,
                          fontSize: '0.75rem',
                          color: 'rgb(99, 115, 129)'
                        }}
                      >
                        paid
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.875rem',
                          lineHeight: 1.43
                        }}
                      >
                        Оплачен
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1.57143,
                          fontSize: '0.75rem',
                          color: 'rgb(99, 115, 129)'
                        }}
                      >
                        wait
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.875rem',
                          lineHeight: 1.43
                        }}
                      >
                        В обработке
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1.57143,
                          fontSize: '0.75rem',
                          color: 'rgb(99, 115, 129)'
                        }}
                      >
                        canceled
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.875rem',
                          lineHeight: 1.43
                        }}
                      >
                        Отменен
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1.57143,
                          fontSize: '0.75rem',
                          color: 'rgb(99, 115, 129)'
                        }}
                      >
                        refunded
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.875rem',
                          lineHeight: 1.43
                        }}
                      >
                        Возвращен
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={3}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          lineHeight: 1.57143,
                          fontSize: '0.75rem',
                          color: 'rgb(99, 115, 129)'
                        }}
                      >
                        error
                      </Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Typography
                        noWrap
                        sx={{
                          fontWeight: 400,
                          fontSize: '0.875rem',
                          lineHeight: 1.43
                        }}
                      >
                        Ошибка
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Item>
        </Grid>
      </Item>
    </React.Fragment>
  );
}
