import {ReactElement, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {
  Grid,
  Box,
  Autocomplete,
  IconButton,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useAppSelector} from "../../../App/hooks/store";
import {IMerchant} from "../../interfaces/merchant";
import {Controller, DefaultValues, FieldValues, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {IMerchantInput} from "../../interfaces/inputs/merchant.inputs.interface";
import {MerchantActions} from "../../actions/merchant";
import {IMerchant as IPaymentMerchant} from "../../../Payment/interfaces/merchant";
import {MerchantActions as PaymentMerchantActions} from "../../../Payment/actions/merchant";
import {Routes} from "./Routes";
import {Params} from "./Params";
import {Close as CloseIcon} from "@mui/icons-material";
import {Backdrop} from "../../../App/components/Backdrop";
import {Dialog, DialogTitle, DialogContent, DialogActions} from "../../../App/components/Dialog";
import {Button} from "../../../App/components/Input/Button";
import {TextField} from "../../../App/components/Input/TextField";

interface Props {
  merchant?: IMerchant,
  open: boolean,
  onChange: (merchant: IMerchant) => void
  onClose: () => void
}

export function Merchant(props: Props): ReactElement | null {
  const dispatch: any = useDispatch();
  const {merchant, open, onClose, onChange} = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [schema, setSchema] = useState<Yup.ObjectSchema<FieldValues>>(Yup
    .object({
      seller: Yup.string().required('Выберите продавца'),
      name: Yup.string().required("Введите наименование"),
      merchant: Yup.object().required("Выберите платежную систему"),
      params: merchant ? Yup.object(Object.entries(merchant.merchant.attributes).reduce((obj, [name, values]) => {
        if (values.hasOwnProperty('name')) {
          return {
            ...obj,
            [name]: Yup.string().required(" ")
          }
        }

        return {
          ...obj,
          [name]: Yup.object(Object.entries(merchant.merchant.attributes[name]).reduce((obj, [name, values]) => {
            return {
              ...obj,
              [name]: Yup.string().required(" ")
            }
          }, {})).required()
        }
      }, {})).required() : Yup.object(),
    }).required())

  const [merchants, setMerchants] = useState<Array<IPaymentMerchant>>([])

  useEffect(() => {
    dispatch(PaymentMerchantActions.all()).then((positions: Array<IPaymentMerchant>) => {
      setMerchants(positions)
    })
  }, []);

  const {seller} = useAppSelector(state => state.seller)

  const values: DefaultValues<any> = {
    seller: seller.token,
    name: merchant ? merchant.name : '',
    merchant: merchant ? merchant.merchant : null,
    params: merchant ? merchant.params : {}
  }

  const {formState: {isSubmitSuccessful}, control, handleSubmit, reset, setError, setValue, clearErrors} = useForm({
    defaultValues: values,
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<IMerchantInput> = (data) => {
    dispatch(merchant ? MerchantActions.update(merchant.id, data) : MerchantActions.create(data)).then((response: IMerchant | void) => {
      reset(data)
      if (response) {
        onChange(response)
        onClose()
      } else if (merchant) {
        onChange({
          ...merchant,
          name: data.name,
          params: data.params
        })
      }
    }).catch((error: { errors: object }) => {
      reset(data)
      if (error.hasOwnProperty("errors")) {
        Object.entries(error.errors).forEach(([name, message]) => {
          setError(name as keyof object, {type: "manual", message: message as string})
        })
      }
    })
  }

  return (
    <Dialog
      open={open}
      fullWidth
      fullScreen={mobile}
      onClose={onClose}
      maxWidth="sm"
      slots={{
        backdrop: Backdrop
      }}
    >
      <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              Платёжная система
            </Grid>
            <Grid item>
              <IconButton
                size="small"
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" alignItems="stretch" justifyContent="center">
            <Grid item xs={12} sx={{height: '100%', minHeight: 80}}>
              <Controller
                name="name"
                control={control}
                render={({
                  field: {onChange, value}, fieldState
                }) => (
                  <TextField
                    required
                    disabled={isSubmitSuccessful}
                    label="Наименование:"
                    error={!!fieldState.error}
                    onChange={onChange}
                    value={value}
                    helperText={fieldState.error?.message}
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sx={{height: '100%', minHeight: 80}}>
              <Controller
                name="merchant"
                control={control}
                render={({
                   field: {onChange, value}, fieldState
                }) => (
                  <Autocomplete
                    fullWidth
                    disabled={!!merchant || isSubmitSuccessful}
                    getOptionLabel={(option) => option.name ?? null}
                    isOptionEqualToValue={(option, value) => option === value}
                    noOptionsText={"Нет данных"}
                    options={merchants}
                    loading={false}
                    value={value}
                    onChange={(e, option: IPaymentMerchant | null) => {
                      onChange(option)
                      clearErrors()
                      if (option) {
                        setSchema(Yup
                          .object({
                            seller: Yup.string().required('Выберите продавца'),
                            name: Yup.string().required("Введите наименование"),
                            merchant: Yup.object().required("Выберите платежную систему"),
                            params: Yup.object(Object.entries(option.attributes).reduce((obj, [name, values]) => {
                              if (values.hasOwnProperty('name')) {
                                return {
                                  ...obj,
                                  [name]: Yup.string().required(" ")
                                }
                              }

                              return {
                                ...obj,
                                [name]: Yup.object(Object.entries(option.attributes[name]).reduce((obj, [name, values]) => {
                                  return {
                                    ...obj,
                                    [name]: Yup.string().required(" ")
                                  }
                                }, {})).required()
                              }
                            }, {})).required(),
                          }).required())
                        setValue('params', Object.entries(option.attributes).reduce((obj, [name, values]) => {
                          if (values.hasOwnProperty('name')) {
                            return {
                              ...obj,
                              [name]: ''
                            }
                          }

                          return {
                            ...obj,
                            [name]: Object.entries(option.attributes[name]).reduce((obj, [name, values]) => {
                              return {
                                ...obj,
                                [name]: ''
                              }
                            }, {})
                          }
                        }, {}))
                      } else {
                        setSchema(Yup
                          .object({
                            seller: Yup.string().required('Выберите продавца'),
                            name: Yup.string().required("Введите наименование"),
                            merchant: Yup.object().required("Выберите платежную систему"),
                            params: Yup.object(),
                          }).required())
                        setValue('params', {})
                      }
                    }}
                    renderTags={() => null}
                    renderInput={(params) => (
                      <TextField
                        required
                        label="Платежная система:"
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        {...params}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Params control={control} disabled={isSubmitSuccessful}/>
            <Routes control={control}/>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
          >
            {merchant ? 'Сохранить' : 'Добавить'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}