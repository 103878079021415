import React, {ReactElement, useState} from "react";

import {
  Drawer as MUIDrawer,
  styled, IconButton, Grid, GridProps, Typography
} from '@mui/material';
import {Close as CloseIcon, InfoOutlined} from "@mui/icons-material";
import {Backdrop} from "../../../App/components/Backdrop";
import {Initialization} from "./Documentation/Initialization";
import {Status} from "./Documentation/Status";
import {Notification} from "./Documentation/Notification";
import {Signature} from "./Documentation/Signature";

const Drawer = styled(MUIDrawer)(({theme}) => ({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: '40%',
    [theme.breakpoints.down('xl')]: {
      width: '60%',
    },
    [theme.breakpoints.down('lg')]: {
      width: '80%',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    boxSizing: 'border-box',
  },
  '& .MuiPaper-root ': {
    backgroundColor: 'rgb(249, 250, 251)',
    borderRightColor: 'rgba(145, 158, 171, 0.2)',
    borderRightStyle: 'dashed',
    borderRightWidth: '1px'
  },
}))

export const Item = styled((props: GridProps) => (
  <Grid
    item
    {...props}
  />
))(({theme}) => ({
  width: '100%'
}));

export const Params = styled((props: GridProps) => (
  <Grid
    item
    {...props}
  />
))(({theme}) => ({
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: 'white'
}));

const types = {
  initialization: {
    title: 'Инициализация платежа',
    component: <Initialization />
  },
  status: {
    title: 'Статус платежа',
    component: <Status />
  },
  notification: {
    title: 'Оповещение',
    component: <Notification />
  },
  signature: {
    title: 'Подпись',
    component: <Signature />
  }
}

interface Props {
  type: 'initialization' | 'status' | 'notification' | 'signature'
}

export function Documentation(props: Props): ReactElement | null {
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<{ title: string, component: ReactElement }>(types[props.type])

  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
          return;
        }

        setOpen(open);
      };

  return type ? (
    <Grid item>
      <IconButton
        size="small"
        color="primary"
        onClick={toggleDrawer(true)}
      >
        <InfoOutlined />
      </IconButton>
      <Drawer
        slots={{
          backdrop: Backdrop
        }}
        anchor="right"
        open={open}
        onClose={toggleDrawer(false)}
      >
        <Grid container direction="column" justifyContent="stretch" alignItems="center" spacing={2} sx={{ padding: '24px' }}>
          <Item>
            <Grid container direction="row" alignItems="center" justifyContent="space-between">
              <Grid item>
                <Typography
                  sx={{
                    fontSize: '1.2rem',
                    fontWeight: 500
                  }}
                >
                  {type.title}
                </Typography>
              </Grid>
              <Grid item>
                <IconButton
                  size="small"
                  onClick={toggleDrawer(false)}
                >
                  <CloseIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Item>
          {type.component}
        </Grid>
      </Drawer>
    </Grid>
  ) : null;
}
