import React, {ReactElement, useEffect, useState} from 'react';
import {
  DragDropContext, DragStart, DragUpdate,
  Droppable, DropResult,
} from 'react-beautiful-dnd';
import {
  Control,
  useFieldArray, useWatch
} from "react-hook-form";
import {IMerchant} from "../../interfaces/merchant";
import {
  Grid,
} from "@mui/material";
import {Merchant} from "./Merchant/Merchant";

export type Props = {
  control: Control,
  disabled: boolean
};

const reorder = (list: Array<IMerchant>, start: number, end: number): Array<IMerchant> => {
  const result: Array<IMerchant> = Array.from(list);
  const [removed] = result.splice(start, 1);
  result.splice(end, 0, removed);

  return result;
};

export function Merchants (props: Props): ReactElement | null {
  const { control, disabled } = props
  const [placeholderProps, setPlaceholderProps] = useState<{y: number, x: number, height: number, width: number} | null>({y: 0, x: 0, height: 0, width: 0});

  const values: Array<IMerchant> = useWatch({
    control,
    name: 'merchants'
  })

  const [items, setItems] = useState<Array<IMerchant>>(values)

  useEffect(() => {
    setItems(values)
  }, [values.length]);

  const { replace } = useFieldArray({
    control,
    name: "merchants"
  });

  useEffect(() => {
    replace(items)
  }, [items]);

  const getDraggedDom = (draggableId: string) => {
    return document.querySelector(`[data-rbd-drag-handle-draggable-id='${draggableId}']`);
  };

  const handleDragStart = (start: DragStart) => {
    const draggedDOM = getDraggedDom(start.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const sourceIndex = start.source.index;
    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop) +
      [...(draggedDOM.parentNode?.children ?? [])]
        .slice(0, sourceIndex)
        .reduce((total, curr) => {
          const style = window.getComputedStyle(curr);
          const marginBottom = parseFloat(style.marginBottom);
          return total + curr.clientHeight + marginBottom;
        }, 0);

    setPlaceholderProps({
      height: clientHeight,
      width: clientWidth,
      y: clientY,
      x: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft,
      ),
    });
  };

  const handleDragEnd = ({ destination, source }: DropResult) => {
    setPlaceholderProps(null);
    if (!destination) return;
    setItems(reorder(items, source.index, destination.index))
  };

  const handleDragUpdate = (update: DragUpdate) => {
    if (!update.destination) {
      return;
    }

    const draggedDOM = getDraggedDom(update.draggableId);

    if (!draggedDOM) {
      return;
    }

    const { clientHeight, clientWidth } = draggedDOM;
    const destinationIndex = update.destination.index;
    const sourceIndex = update.source.index;

    const childrenArray = [...(draggedDOM.parentNode?.children ?? [])];
    const movedItem = childrenArray[sourceIndex];
    childrenArray.splice(sourceIndex, 1);

    const updatedArray = [
      ...childrenArray.slice(0, destinationIndex),
      movedItem,
      ...childrenArray.slice(destinationIndex + 1),
    ];

    const clientY =
      parseFloat(window.getComputedStyle(draggedDOM.parentNode as Element).paddingTop) +
      updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
        const style = window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

    setPlaceholderProps({
      height: clientHeight,
      width: clientWidth,
      y: clientY,
      x: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode as Element).paddingLeft,
      ),
    });
  };

  return items.length ? (
    <DragDropContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      onDragUpdate={handleDragUpdate}
    >
      <Droppable droppableId="merchants">
        {(provided, snapshot) => (
          <Grid
            item
            {...provided.droppableProps}
            ref={provided.innerRef}
            sx={{ width: '100%', position: "relative", padding: 'grid', }}
          >
            {items.map((merchant, index) => (
              <Merchant
                key={merchant.id}
                index={index}
                control={control}
                merchant={merchant}
                disabled={disabled}
                remove={(id: number) => {
                  setItems(items.filter(item => item.id !== id))
                }}
              />
            ))}
            {provided.placeholder}
            {(placeholderProps && snapshot.isDraggingOver) ? (
              <Grid item
                className="placeholder"
                sx={{
                  position: 'absolute',
                  border: '2px',
                  borderColor: 'rgb(99, 115, 129, 0.08)',
                  borderStyle: 'dashed',
                  borderRadius: '6px',
                  top: placeholderProps.y,
                  left: placeholderProps.x,
                  height: placeholderProps.height,
                  width: placeholderProps.width,
                }}
              />
            ) : null}
          </Grid>
        )}
      </Droppable>
    </DragDropContext>
  ) : null;
}
