import {AccountActionsTypes, IAccount} from "../interfaces/account";
import {AnyAction} from "@reduxjs/toolkit";

interface IAccountState {
  account: IAccount | null
}

const initial: IAccountState = {
  account: null
};

export function Account(state = initial, action: AnyAction) {
  switch (action.type) {
    case AccountActionsTypes.FETCH_ACCOUNT:
      return {
        ...state,
        ...{
          account: action.payload
        }
      };
    case AccountActionsTypes.UPDATE_ACCOUNT_SELLER:
      return {
        ...state,
        ...{
          account: {
            ...state.account,
            sellers: state.account?.sellers?.map(seller => ({
              ...seller,
              ...((action.payload.token === seller.token)
                ? {name: action.payload.name}
                : {}
              )
            }))
          }
        }
      };
    case AccountActionsTypes.CLEAR_ACCOUNT:
      return {
        ...state,
        ...{
          account: null
        }
      };
    default:
      return state;
  }
}