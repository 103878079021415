import React, {ReactElement, useState} from "react"
import {
  Grid,
  Fade,
  IconButton,
  MenuList
} from "@mui/material";
import {MoreVert as MoreVertIcon} from "@mui/icons-material";
import {Edit} from "./Actions/Edit";
import {Delete} from "./Actions/Delete";
import {IGateway} from "../../../../Payment/interfaces/gateway";
import {Menu} from "../../../../App/components/Menu";
import {Payment} from "./Actions/Payment";

interface Props {
  gateway: IGateway,
  onChange?: (gateway: IGateway) => void,
  onDelete?: (gateway: IGateway) => void,
}

export function Actions(props: Props): ReactElement | null {
  const {gateway, onChange, onDelete} = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item>
      <IconButton
        size="small"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
      >
        <MenuList dense>
          <Edit
            gateway={gateway}
            onChange={onChange}
          />
          <Payment
            gateway={gateway}
          />
          <Delete
            gateway={gateway}
            onChange={(gateway) => {
              if (onDelete) {
                onDelete(gateway)
              }
            }}
          />
        </MenuList>
      </Menu>
    </Grid>
  )
}
