import React, {ReactElement, useState} from "react";
import {
  Grid,
  Divider, Fade,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuList,
  Typography,
} from "@mui/material";
import {
  ExitToApp as ExitToAppIcon
} from "@mui/icons-material";
import {NavigateFunction, useNavigate} from "react-router";
import {useAppSelector} from "../../hooks/store";
import {Menu, MenuItem} from "../Menu";
import {Logo} from "../Logo";

export function Account(): ReactElement | null {
  const {account} = useAppSelector(state => state.account)

  const navigate: NavigateFunction = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return account ? (
    <Grid item>
      <IconButton
        color="primary"
        onClick={handleClick}
      >
        <Logo height={24} width={24} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClick={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        TransitionComponent={Fade}
      >
        <MenuList dense>
          <MenuItem disabled>
            <Typography variant="inherit" noWrap>
              {account.email}
            </Typography>
          </MenuItem>
          <Divider sx={{
            borderColor: 'rgba(145, 158, 171, 0.2)',
            borderStyle: 'dashed',
            borderWidth: '0px 0px thin'
          }}/>
          <MenuItem
            onClick={() => {
              navigate('/logout')
            }}
          >
            <ListItemIcon>
              <ExitToAppIcon fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Выйти</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Grid>
  ) : null;
}
