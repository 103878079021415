import React, {ReactElement, useEffect, useState} from "react";

import {
  Grid,
  IconButton,
  SwipeableDrawer,
  useMediaQuery, useTheme,
} from '@mui/material';
import {useAppSelector} from "../../hooks/store";
import {Content} from "./Content";
import {Menu as MenuIcon} from "@mui/icons-material";
import {Backdrop} from "../Backdrop";

export function Swipeable(): ReactElement | null {
  const theme = useTheme();
  const tablet = useMediaQuery(theme.breakpoints.down('lg'));
  const {account} = useAppSelector(state => state.account)

  const [open, setOpen] = useState<boolean>(false);

  const toggleDrawer =
    (open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event && event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
          return;
        }

        setOpen(open);
      };

  useEffect(() => {
    toggleDrawer(false)
  }, [tablet])

  return (account && tablet) ? (
    <Grid item>
      <IconButton color="primary" onClick={toggleDrawer(true)}>
        <MenuIcon/>
      </IconButton>
      <SwipeableDrawer
        sx={{
          width: 279,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 279,
            boxSizing: 'border-box',
          },
        }}
        slots={{
          backdrop: Backdrop
        }}
        anchor="left"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Content onClose={() => setOpen(false)}/>
      </SwipeableDrawer>
    </Grid>
  ) : null;
}
