import React, {DetailedReactHTMLElement, HTMLAttributes, ReactElement} from "react";
import {
  Box, Grid,
  AppBar as MUIAppBar,
  Container, Fab as MUIFab, Fade,
  Toolbar as MUIToolbar, useMediaQuery,
  useScrollTrigger, useTheme, styled
} from "@mui/material";
import {KeyboardArrowUp as KeyboardArrowUpIcon} from "@mui/icons-material";
import {Account} from "./Header/Account";
import {Swipeable} from "./Drawer/Swipeable";

type Props = {
  window?: () => Window;
  children: ReactElement;
}

const AppBar = styled(MUIAppBar)(({theme}) => ({
  height: "80px",
  backgroundColor: 'rgb(249, 250, 251)',
  '&.MuiAppBar-colorPrimary': {
    color: "rgb(22 28 45)"
  }
}))

const Toolbar = styled(MUIToolbar)(({theme}) => ({
  padding: '0 !important',
  height: "80px"
}))

const Fab = styled(MUIFab)(({theme}) => ({
  boxShadow: "none",
  color: "white",
  backgroundColor: 'rgba(0, 167, 111, 0.5)',
  borderRadius: '8px',
  "&:hover": {
    backgroundColor: theme.palette.primary.dark
  },
}))

const ElevationScroll = (props: Props) => {
  const {children} = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children as DetailedReactHTMLElement<HTMLAttributes<HTMLElement>, HTMLElement>, {
    style: {
      backdropFilter: trigger ? 'blur(6px)' : 'none',
      backgroundColor: trigger ? 'rgba(249, 250, 251, 0.8)' : 'none',
      boxShadow: "none"
    },
    elevation: trigger ? 1 : 0
  } as HTMLAttributes<HTMLElement>);
}

const ScrollTop = (props: Props) => {
  const theme = useTheme();

  const {children} = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = (
      (event.target as HTMLDivElement).ownerDocument || document
    ).querySelector('#header');

    if (anchor) {
      anchor.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{position: 'fixed', bottom: theme.spacing(2), left: theme.spacing(2), zIndex: 1110}}
      >
        {children}
      </Box>
    </Fade>
  );
}

export function Header(): ReactElement {
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <React.Fragment>
      <ElevationScroll>
        <AppBar>
          <Toolbar
            sx={desktop ? {
              marginLeft: '279px'
            } : {}}
          >
            <Container maxWidth="xl">
              <Grid container direction="row" alignItems="center" justifyContent={desktop ? "flex-end" : "space-between"}>
                <Swipeable/>
                <Account/>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar id="header"/>
      <ScrollTop>
        <Fab size="small">
          <KeyboardArrowUpIcon/>
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
