import {SellerActionsTypes} from "../interfaces/seller";
import {AnyAction} from "@reduxjs/toolkit";
import {ISeller} from "../interfaces/seller";

interface ISellerState {
  seller: ISeller | null
}

const initial: ISellerState = {
  seller: null
};

export function Seller(state = initial, action: AnyAction) {
  switch (action.type) {
    case SellerActionsTypes.FETCH_SELLER:
      return {
        ...state,
        ...{
          seller: action.payload
        }
      };
    case SellerActionsTypes.UPDATE_SELLER:
      return {
        ...state,
        ...{
          seller: {
            ...state.seller,
            ...action.payload
          }
        }
      };
    case SellerActionsTypes.CLEAR_SELLER:
      return {
        ...state,
        ...{
          seller: null
        }
      };
    default:
      return state;
  }
}