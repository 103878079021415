import React, {ReactElement, useEffect, useState} from "react"
import {
  Grid,
  IconButton
} from "@mui/material";
import {DateRange as DateRangeIcon} from "@mui/icons-material";
import {Dayjs} from "dayjs";
import {DateRangeCalendar} from "../../App/components/Input/Date/DateRangeCalendar";
import {IFilter} from "../../App/interfaces/Table/filter";
import {Popover} from "../../App/components/Popover";

interface Props {
  filter: IFilter,
  setFilter: (filter: IFilter) => void
}

export function Filter(props: Props): ReactElement | null {
  const {filter, setFilter} = props
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [dates, setDates] = useState<Dayjs[]>([]);

  useEffect(() => {
    if (dates.length || filter.intervals?.hasOwnProperty('created')) {
      setFilter({
        ...filter,
        intervals: {
          ...filter.intervals,
          'created': {
            start: dates.length ? dates[0].unix() : undefined,
            end: dates.length > 1 ? dates[1].unix() : undefined
          }
        }
      })
    }
  }, [dates]);

  return (
    <Grid item>
      <IconButton
        size="small"
        color={dates.length ? "primary" : "default" as 'primary' | 'default'}
        onClick={handleClick}
      >
        <DateRangeIcon />
      </IconButton>
      <Popover
        disableScrollLock
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <DateRangeCalendar
          dates={dates}
          setDates={setDates}
        />
      </Popover>
    </Grid>
  )
}
